import React, { useEffect,useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
  CDBIcon,
} from 'cdbreact';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleSwitch from '../ToggleSwitch';
import { useSidebar } from '../SidebarContext';

import { fetchToken } from '../../Auth';
import getEnvConfig from '../../config';

const Sidebar = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [isCollapsed, setIsCollapsed] = useState(isMobile);


  const { mode, toggleMode } = useSidebar();
  const [isSuperUser, setIsSuperUser] = useState(false);
  const { backendUrl } = getEnvConfig();
  const token = fetchToken();

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    const checkSuperUser = async () => {
      try {
        const response = await fetch(`${backendUrl}/users/check_superuser`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_token: token }),
        });
        const data = await response.json();
        if (data.super_user) {
          setIsSuperUser(true);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    if (token) {
      checkSuperUser();
    }
  }, [backendUrl, token]);




    const renderDriverMenu = () => (
    <>
    <Nav.Link href="/users/driver/dashboard" >
      <CDBSidebarMenuItem icon="columns">Dashboard</CDBSidebarMenuItem>
    </Nav.Link>
    <Nav.Link href='/users/drivers/driver-profile'>
      <CDBSidebarMenuItem icon="user">Profile</CDBSidebarMenuItem>
    </Nav.Link>
    <Nav.Link href="/users/drivers/find-chargers" >
      <CDBSidebarMenuItem icon="bolt">Find Chargers</CDBSidebarMenuItem>
    </Nav.Link>
    
  
    <Nav.Link href="/users/drivers/driver-charging-history" >
      <CDBSidebarMenuItem icon="clock" > Charging History</CDBSidebarMenuItem>
    </Nav.Link>
    <Nav.Link href="/contact_us" >
      <CDBSidebarMenuItem icon="phone"> Support</CDBSidebarMenuItem>
    </Nav.Link>
    <Nav.Link href="/sign_out" >
          <CDBSidebarMenuItem icon="stop"> Log Out</CDBSidebarMenuItem>
    </Nav.Link>       
    </>
  );



  const renderPropertyManagerMenu = () => (
    <>
	<Nav.Link href="/users/business/dashboard" >
	  <CDBSidebarMenuItem icon="columns">Dashboard</CDBSidebarMenuItem>
	</Nav.Link>
	<Nav.Link href='/users/business/property-profile'>
	  <CDBSidebarMenuItem icon="user">Profile</CDBSidebarMenuItem>
	</Nav.Link>
               
	<Nav.Link href="/users/charger_options" >
	  <CDBSidebarMenuItem icon="bolt" > Charger Options</CDBSidebarMenuItem>
	</Nav.Link>
	<Nav.Link href='/users/business/charging-history' >
	<CDBSidebarMenuItem icon="clock" > Usage History</CDBSidebarMenuItem>
	</Nav.Link>
  <Nav.Link href="/users/business/payments" >
    <CDBSidebarMenuItem icon="money-bill-wave" > Payments</CDBSidebarMenuItem>
  </Nav.Link>
	<Nav.Link href="/contact_us" >
	  <CDBSidebarMenuItem icon="phone"> Support</CDBSidebarMenuItem>
	</Nav.Link>

	<Nav.Link href="/sign_out" >
	  <CDBSidebarMenuItem icon="stop"> Log Out</CDBSidebarMenuItem>
	</Nav.Link>      

  {isSuperUser && (
        <Nav.Link href="/users/charger_registration">
          <CDBSidebarMenuItem icon="plug">Charger Registration</CDBSidebarMenuItem>
        </Nav.Link>
      )}     

  {isSuperUser && (
        <Nav.Link href="/users/charger_manage_groups_and_chargers" >
          <CDBSidebarMenuItem icon="map-marker-alt" > Manage Deployment</CDBSidebarMenuItem>
        </Nav.Link>         
        )} 

{isSuperUser && (
        <Nav.Link href="/users/messages" >
          <CDBSidebarMenuItem icon="envelope" > Messages</CDBSidebarMenuItem>
        </Nav.Link>         
        )}         

    </>
  );
 

  return (
    <div  style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <CDBSidebar breakpoint={540} toggled={isMobile} textColor="#fff" style={{backgroundImage: 'linear-gradient(to right,#3b6ff5,#3b6ff5,#3b6ff5' }}>
        <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>   
          <Col className="text-center"> 
              <img src="/images/evchron_logo2.png" width="50" height="45" alt="EV Chron"/>
          </Col>
          <Col className="text-center"> 
              <a href="/" className="text-decoration-none" style={{ color: 'inherit' }}>
                  EV Chron
              </a>
          </Col>                    
        </CDBSidebarHeader>

        <div className="text-center my-3">
          <ToggleSwitch
            label={mode === 'driver' ? 'Driver' : 'Property Manager'}
            isChecked={mode === 'driver'}
            onChange={toggleMode}
          />
        </div>

        <CDBSidebarContent className="sidebar-content">
          <CDBSidebarMenu>
		{mode === 'driver' ? renderDriverMenu() : renderPropertyManagerMenu()}
          </CDBSidebarMenu>
        </CDBSidebarContent>

        <CDBSidebarFooter style={{ textAlign: 'center' }}>

        </CDBSidebarFooter>
      </CDBSidebar>
    </div>
  );
};

export default Sidebar;